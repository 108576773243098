import React from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../ContextApi/StateProvider";
import "../css/product.css";

function Product({ product }) {
  const [{ cart }, dispatch] = useStateValue();
  const addToCart = () => {
    // Dispatch the item into the data layer
    {
      product.Sizes
        ? dispatch({
            type: "ADD_TO_CART",
            item: {
              id: product.id,
              title: product.Title,
              image: product.Images,
              price: product.Price,
              count: product.Count,
              sizes: product.Sizes,
              small: product.small,
              medium: product.medium,
              large: product.large,
            },
          })
        : dispatch({
            type: "ADD_TO_CART",
            item: {
              id: product.id,
              title: product.Title,
              image: product.Images,
              price: product.Price,
              count: product.Count,
            },
          });
    }
  };

  return (
    <div className="product">
      <div key={product.id} className="product__card">
        {product.InStock === true ? (
          <span
            style={{
              color: "goldenrod",
              backgroundColor: "black",
              borderRadius: "10px",
              paddingLeft: "5px",
              paddingRight: "5px",
              paddingBottom: "3px",
              marginTop: "-5px",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            In Stock
          </span>
        ) : (
          <span
            style={{
              color: "white",
              padding: "3px",
              backgroundColor: "black",
              borderRadius: "10px",
              paddingLeft: "6px",
              paddingRight: "6px",
              paddingBottom: "3px",
              marginTop: "-5px",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            Out of Stock
          </span>
        )}
        <Link to={`/${product.id}`}>
          <img src={product.Images[0]} alt="" />
        </Link>

        <div className="product__box">
          <h6 className="product__title">
            <Link to={`/${product.id}`}>{product.Title}</Link>
          </h6>

          <h4 className="product__price">R{product.Price}</h4>
          {product.InStock === true ? (
            <button className="product__btn" onClick={addToCart}>
              Add to cart
            </button>
          ) : (
            <button
              className="product__OOSbtn"
              type="button"
              disabled
              style={{ border: "2px solid lightgray", borderRadius: "30px" }}
            >
              Add to cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
