import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";
import { useStateValue } from "../ContextApi/StateProvider";
import { auth } from "../firebase";
import { Modal } from "./Modal";
import IGK from "../img/HeaderLogo.png";

function Header() {
  const [{ cart, user }, dispatch] = useStateValue();

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <div className="header navbar-fixed">
      <nav>
        <div className="nav-wrapper white">
          <Link to="/">
            <a href="#!">
              <img className="header__logo" src={IGK} alt="logo" />
            </a>
          </Link>

          <ul className="right header__cart">
            <li>
              <div className="header__option">
                <h5 className="header__optionLineOne">Hello Guest</h5>
              </div>
            </li>

            {/* {user ? (
              <li>
                <Link to="/ordersHistory">
                  <div className="header__option">
                    <span className="header__optionLineTwo">
                      {" "}
                      Order History
                    </span>
                  </div>
                </Link>
              </li>
            ) : null} */}

            {user ? (
              <>
                <li className="header__cartIcon">
                  <Link to="/Checkout">
                    <a href="#" className="btn-floating black z-depth-0">
                      <i className="large material-icons">add_shopping_cart</i>
                    </a>
                  </Link>
                </li>
                <li className="header__cartCount">
                  <span className="badge white-text black new">
                    {cart?.length}
                  </span>
                </li>
              </>
            ) : (
              <>
                <li onClick={openModal} className="header__cartIcon">
                  <a
                    className="btn-floating black z-depth-0"
                    style={{ marginRight: "30px" }}
                  >
                    <i className="large material-icons">add_shopping_cart</i>
                  </a>
                </li>
                <li onClick={openModal} className="header__cartCount">
                  <span className="badge white-text black new">
                    {cart?.length}
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
        <Modal showModal={showModal} setShowModal={setShowModal} />
      </nav>
    </div>
  );
}

export default Header;
