import React from "react";
import "../css/footer.css";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";

function Footer() {
  const handleEmail = () => {
    window.open("mailto:info@ingubokagogo.co.za");
  };
  return (
    <div className="footer__">
      <span>
        © {new Date().getFullYear()} Ingubo kaGogo. All rights reserved
      </span>
      <div className="footer__socialLinks">
        <a
          className="footer__socialLink"
          href="https://www.instagram.com/ingubo_kagogo/"
          target="_blank"
        >
          <AiFillInstagram className="footer__socialIcon" />
        </a>
        <a
          href="https://web.facebook.com/Ingubo-Kagogo-105273171342132/?hc_ref=ARSnVt7pmsxNN47nL9FShWyWfjMjcVN50Oc0jJ88FZ2vUxxcB0xh3zyTm3pyEhjWuuQ&fref=nf&__xts__%5B0%5D=68.ARDXnd6qWtN0ZNJmQ4yMtpkNNqpH-nx7-MfI-qDZZShtGiXXSPGgwOw-EZfMNaH6ybZf-bYzuMEqYioxm0AnVlddEKMtZo6hr3DPT0J2mk0zHdJbDG2ADEv_qPt3z9TmH6IUkErEgw2srQshcGdSGWYeHyfwLqfK6Iwul7QbwSWPcqoYbLzzHoOlfRBXLkjVL113Pto9Xa0IQKUYR3wnXPJ28mtLMLMhHJ1cbhnJmpBDZzWHux7c9PoM4dPhZu1B8GnSm6pZH-JSUwbsShZLPNMvWPzmQOoCRjK27mGZAvTgTcy3F6w&__tn__=kC-R"
          target="_blank"
          className="footer__socialLink"
        >
          <AiFillFacebook className="footer__socialIcon" />
        </a>
        <MdEmail className="footer__socialIcon" onClick={handleEmail} />
      </div>
    </div>
  );
}

export default Footer;
