export default function validateInfo(values) {
  let errors = {};
  // FirstName
  if (!values.firstName.trim()) {
    errors.firstName = "FirstName required";
  }
  // LastName
  if (!values.lastName.trim()) {
    errors.lastName = "LastName required";
  }

  // Email
  if (!values.email) {
    errors.email = "Email required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  // Mobile
  if (!values.mobile) {
    errors.mobile = "Mobile number required";
  } else if (!/^\d{10}$/.test(values.mobile)) {
    errors.mobile = "Mobile number is invalid";
  }

  // Location
  if (!values.location) {
    errors.location = "Delivery address is required";
  }

  // Postal code
  if (!values.postalCode) {
    errors.postalCode = "Postal code is required";
  } else if (values.postalCode.length < 4) {
    errors.postalCode = "Postal needs to be 4 digits";
  }

  return errors;
}
