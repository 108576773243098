import { useEffect, useState } from "react";
import { useStateValue } from "./ContextApi/StateProvider";
import { db } from "./firebase";

const useForm = (callback, validate) => {
  const [{ user }, dispatch] = useStateValue();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    location: "",
    postalCode: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(
        db
          .collection("users")
          .doc(user?.uid)
          .collection("orders")
          .doc("order")
          .set({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobile: values.mobile,
            location: values.location,
            postalCode: values.postalCode,
          })
          .then(function () {
            console.log("STATUS>>>>>>200");
          })
      );
    }
  }, [errors]);
  return { handleChange, values, handleSubmit, errors };
};

export default useForm;
