import React, { useState } from "react";
import "../css/checkoutForm.css";
import useForm from "../useForm";
import Subtotal from "./Subtotal";
import validate from "../validateInfo";

function CheckoutForm() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }

  const { handleChange, values, handleSubmit, errors } = useForm(
    submitForm,
    validate
  );
  return (
    <div className="CheckoutForm">
      <div className="row">
        <form className="col s12">
          <div className="row">
            <div className="input-field col s6">
              <i className="material-icons prefix">account_circle</i>
              <input
                id="icon_prefix"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                type="text"
                className="validate"
              />
              {errors.firstName && <p>{errors.firstName}</p>}
              <label for="icon_prefix">First Name</label>
            </div>
            <div class="input-field col s6">
              <input
                id="last_name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                type="text"
                className="validate"
              />
              {errors.lastName && <p>{errors.lastName}</p>}
              <label for="last_name">Last Name</label>
            </div>
            <div class="row">
              <div class="input-field col s12">
                <i className="material-icons prefix CheckoutForm__email">
                  email
                </i>
                <input
                  id="icon_email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  type="email"
                  className="validate"
                />
                {errors.email && <p>{errors.email}</p>}
                <label for="icon_email">Email</label>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s12">
                <i className="material-icons prefix CheckoutForm__phone">
                  phone
                </i>
                <input
                  id="icon_prefix"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  type="tel"
                  className="validate"
                />
                {errors.mobile && <p>{errors.mobile}</p>}
                <label for="icon_prefix">Mobile</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12">
                <i className="material-icons prefix CheckoutForm__Loc">
                  add_location
                </i>
                <input
                  id="icon_prefix"
                  name="location"
                  value={values.location}
                  onChange={handleChange}
                  type="text"
                  className="validate"
                />
                {errors.location && <p>{errors.location}</p>}
                <label for="icon_prefix">Location</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12">
                <i className="material-icons prefix CheckoutForm__Loc">
                  add_location
                </i>
                <input
                  id="icon_prefix"
                  name="postalCode"
                  value={values.postalCode}
                  onChange={handleChange}
                  type="text"
                  className="validate"
                />
                {errors.postalCode && <p>{errors.postalCode}</p>}
                <label for="icon_prefix">Postal Code</label>
              </div>
            </div>
            <Subtotal handleSubmit={handleSubmit} isSubmitted={isSubmitted} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CheckoutForm;
