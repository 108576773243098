import React from "react";
import "../css/products.css";
import IGK from "../img/IGK6.png";
import Product from "./Product";

function Products({ products }) {
  return (
    <div className="products">
      <div className="products__container">
        <img className="products__image" src={IGK} alt="Ingubokagogo" />
        <div className="products__product">
          {products?.map((product) => (
            <Product product={product} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;
