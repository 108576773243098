import React from "react";
import { useState, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import "../css/productDetails.css";
import DetailsThumb from "./DetailsThumb";

function ProductDetails({ products }) {
  const { id } = useParams();
  const [index, setIndex] = useState(0);
  const imgDiv = useRef();

  const details = products.filter((product, index) => {
    return product.id === id;
  });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    imgDiv.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  return (
    <>
      {details.map((product) => (
        <div className="productDetails" key={product.id}>
          <div
            className="productDetails__image-container"
            onMouseMove={handleMouseMove}
            style={{ backgroundImage: `url(${product.Images[index]})` }}
            ref={imgDiv}
            onMouseLeave={() =>
              (imgDiv.current.style.backgroundPosition = `center`)
            }
          />

          <div className="productDetails__box-details">
            <h2 title={product.Title}>{product.Title}</h2>
            <hr />
            <h3>R{product.Price}</h3>
            <DetailsThumb Images={product.Images} setIndex={setIndex} />
            <Link to="/">
              <button className="productDetails__btn">Back</button>
            </Link>
          </div>
          {product.Description ? (
            <div className="productDetails__Dec">
              <h4>Description</h4>
              <p className="productDetails__description">
                {product.Description[0]}
              </p>
              <p className="productDetails__description">
                {product.Description[1]}
              </p>
              <p className="productDetails__description">
                {product.Description[2]}
              </p>
              <p className="productDetails__description">
                {product.Description[3]}
              </p>

              <h5 className="productDetails__Dimensions">Dimensions</h5>
              <p>{product.Description[4]}</p>
              <h5>Whats in the box</h5>
              <p>{product.Description[5]}</p>
            </div>
          ) : (
            <div className="productDetails__Dec2">
              <h4>Description</h4>
              <p className="productDetails__description2">
                {product.Description2[0]}
              </p>
              <p className="productDetails__description2">
                {product.Description2[1]}
              </p>
              <h5 className="productDetails__Dimensions2">Dimensions</h5>
              <p>{product.Description2[2]}</p>
              <h5>Whats in the box</h5>
              <p>{product.Description2[3]}</p>
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export default ProductDetails;
