import React from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../ContextApi/StateProvider";
import "../css/checkout.css";
import blanket from "../img/blanket.png";
import CheckoutForm from "./CheckoutForm";
import CheckoutProduct from "./CheckoutProduct";
import Subtotal from "./Subtotal";

function Checkout() {
  const [{ cart }, dispatch] = useStateValue();
  return (
    <div>
      {cart.length > 0 && (
        <div className="checkout">
          <div className="checkout__left">
            <img className="checkout__ad" src={blanket} alt="blanket" />
            <div>
              <h4 className="checkout__title">Your Cart</h4>

              {cart.map((item) => (
                <CheckoutProduct
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  count={item.count}
                  sizes={item.sizes}
                  small={item.small}
                  medium={item.medium}
                  large={item.large}
                />
              ))}
            </div>
          </div>

          <div className="checkout__right">
            <CheckoutForm />
          </div>
        </div>
      )}
      {!cart.length && (
        <div className="checkout__empty-cart">
          <Link to="/">
            <button className="checkout__btn pulse">Add to cart</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Checkout;
