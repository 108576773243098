import React from "react";
import "../css/subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "../ContextApi/StateProvider";
import { getCartTotal } from "../ContextApi/Reducer";
import { Link, useNavigate } from "react-router-dom";

function Subtotal({ handleSubmit, isSubmitted }) {
  const navigate = useNavigate();
  const [{ cart, user }] = useStateValue();

  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(value) => (
          <>
            <p>
              Delivery fee : <strong>R150</strong>
              <br />
              Subtotal ({cart.length} items) :<strong>{value}</strong>
            </p>
          </>
        )}
        decimalScale={2}
        value={getCartTotal(cart)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"ZAR"}
      />
      {isSubmitted ? (
        navigate("/payment")
      ) : (
        <button onClick={handleSubmit} className="subtotal__btn">
          Proceed to checkout
          {/* <Link style={{ textDecoration: "none", color: "white" }} to="/payment">
          Proceed to Checkout
        </Link> */}
        </button>
      )}
    </div>
  );
}

export default Subtotal;
