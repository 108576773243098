import React, { useEffect } from "react";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { getCartTotal } from "../ContextApi/Reducer";
import { useStateValue } from "../ContextApi/StateProvider";
import "../css/payment.css";
import { db } from "../firebase";
import CheckoutProduct from "./CheckoutProduct";
import { useNavigate } from "react-router-dom";
import zapper from "zapperjs";
import firebase from "firebase";

function Payment() {
  const [{ cart, user }, dispatch] = useStateValue();

  const navigate = useNavigate();

  useEffect(() => {
    const paymentWidget = new zapper.payments.PaymentWidget(
      ".payment__details",
      {
        merchantId: 51988,
        siteId: 65550,
        amount: getCartTotal(cart),
        reference: "Ingubo KaGogo",
      }
    );

    paymentWidget.on(
      "payment",
      ({ status, paidAmount, zapperId, reference }) => {
        console.log(
          `Received payment of ${paidAmount} with reference ${reference}!`
        );
        if (status === 2) {
          db.collection("users")
            .doc(user?.uid)
            .collection("orders")
            .doc("order")
            .set(
              {
                cart: cart,
                invoice: zapperId,
                amount: paidAmount,
                created: firebase.firestore.FieldValue.serverTimestamp(),
              },
              { merge: true }
            );

          dispatch({
            type: "EMPTY_BASKET",
          });
          navigate("/");
        }
      }
    );
  }, []);

  return (
    <div className="payment">
      <div className="payment__container">
        {/* payment section -Review items*/}
        <div className="payment__section">
          <div className="payment__title">
            <h4>Review items</h4>
          </div>
          {cart.length > 0 ? (
            <div className="payment__items">
              {cart.map((item) => (
                <CheckoutProduct
                  id={item.id}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                  hideButton
                />
              ))}
            </div>
          ) : (
            navigate("/")
          )}
        </div>

        {/* payment section -payment method*/}

        <div className="payment__section2">
          <div className="payment__title">
            <h4>Payment method</h4>
          </div>
          <div className="payment__details"></div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
