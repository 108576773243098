import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Products from "./components/Products";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Checkout from "./components/Checkout";
import ProductDetails from "./components/ProductDetails";
import { auth, db } from "./firebase";
import Payment from "./components/Payment";

import OrdersHistory from "./components/OrdersHistory";
import Login from "./components/Login";
import { useStateValue } from "./ContextApi/StateProvider";
import Footer from "./components/Footer";

function useProducts() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    db.collection("Products").onSnapshot((snapshot) => {
      const newProducts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(newProducts);
    });
  }, []);

  return products;
}

function App() {
  const products = useProducts();
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      console.log("THE USER IS >>>>", authUser);

      if (authUser) {
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <>
                <Header />
                <Products products={products} />
              </>
            }
          />
          <Route
            path="/:id"
            element={
              <>
                <Header />
                <ProductDetails products={products} />
              </>
            }
          />
          <Route
            path="/checkout"
            element={
              <>
                <Header />
                <Checkout />
              </>
            }
          />
          {/* <Route
            path="/ordersHistory"
            element={
              <>
                <Header />
                <OrdersHistory />
              </>
            }
          /> */}
          <Route
            path="/payment"
            element={
              <>
                <Header />

                <Payment />
              </>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
