import React from "react";
import { useStateValue } from "../ContextApi/StateProvider";
import "../css/checkoutProduct.css";

function CheckoutProduct({
  id,
  image,
  title,
  price,
  hideButton,
  count,
  sizes,
}) {
  const [{ cart }, dispatch] = useStateValue();

  const removeFromCart = () => {
    //   Remove the item from the cart
    dispatch({
      type: "REMOVE_FROM_CART",
      id: id,
    });
  };

  //Toggle Small Size
  const toggleSmall = (id) => {
    cart.forEach((item) => {
      if (item.id === id) {
        item.small = !item.small;
      }
    });
    dispatch({
      type: "ADD_SIZE_TO_CART",
      cart: cart,
    });
  };
  //Toggle Medium Size
  const toggleMedium = () => {
    cart.forEach((item) => {
      if (item.id === id) {
        item.medium = !item.medium;
      }
    });
    dispatch({
      type: "ADD_SIZE_TO_CART",
      cart: cart,
    });
  };
  //Toggle Large Size
  const toggleLarge = () => {
    cart.forEach((item) => {
      if (item.id === id) {
        item.large = !item.large;
      }
    });
    dispatch({
      type: "ADD_SIZE_TO_CART",
      cart: cart,
    });
  };

  //Count reduction
  const reduction = (id) => {
    cart.forEach((item) => {
      if (item.id === id) {
        item.count === 1 ? (item.count = 1) : (item.count -= 1);
      }
    });
    dispatch({
      type: "UPDATE_CART",
      cart: cart,
    });
  };

  //Count increase
  const increase = (id) => {
    cart.forEach((item) => {
      if (item.id === id) {
        item.count += 1;
      }
    });
    dispatch({
      type: "UPDATE_CART",
      cart: cart,
    });
  };
  return (
    <>
      {sizes ? (
        <div className="checkoutProduct">
          <img className="checkoutProduct__image" src={image[0]} alt="" />
          <div className="checkoutProduct__info">
            <p className="checkoutProduct__title">{title}</p>
            <p className="checkoutProduct__price"></p>
            <small>R</small>
            <strong>{price}</strong>
            <br />
            <div style={{ display: "flex" }} className="checkoutProduct__sizes">
              <label
                style={{ fontSize: "20px" }}
                className="checkoutProduct__container"
              >
                S
                <input type="checkbox" onChange={() => toggleSmall(id)} />
                <span className="checkoutProduct__checkMark"></span>
              </label>
              <label
                style={{ fontSize: "20px" }}
                className="checkoutProduct__container"
              >
                M
                <input type="checkbox" onChange={() => toggleMedium(id)} />
                <span className="checkoutProduct__checkMark"></span>
              </label>
              <label
                style={{ fontSize: "20px" }}
                className="checkoutProduct__container"
              >
                L
                <input type="checkbox" onChange={() => toggleLarge(id)} />
                <span className="checkoutProduct__checkMark"></span>
              </label>
            </div>
            <div className="checkoutProduct__count">
              <button onClick={() => reduction(id)}>
                <span>-</span>
              </button>

              <span>{count}</span>

              <button onClick={() => increase(id)}>
                <span>+</span>
              </button>
            </div>
          </div>

          {!hideButton && (
            <button className=" checkoutProduct__btn" onClick={removeFromCart}>
              Remove from cart
            </button>
          )}
        </div>
      ) : (
        <div className="checkoutProduct">
          <img className="checkoutProduct__image" src={image[0]} alt="" />
          <div className="checkoutProduct__info">
            <p className="checkoutProduct__title">{title}</p>
            <p className="checkoutProduct__price"></p>
            <small>R</small>
            <strong>{price}</strong>

            <div className="checkoutProduct__count">
              <button onClick={() => reduction(id)}>
                <span>-</span>
              </button>
              <span>{count}</span>
              <button onClick={() => increase(id)}>
                <span>+</span>
              </button>
            </div>
          </div>

          {!hideButton && (
            <button className=" checkoutProduct__btn" onClick={removeFromCart}>
              Remove from cart
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default CheckoutProduct;
