import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBD3tkxNEgxUD1Xlh1JTOLYHIJQn8fLPio",
  authDomain: "ingubokagogo-13424.firebaseapp.com",
  databaseURL: "https://ingubokagogo-13424.firebaseio.com",
  projectId: "ingubokagogo-13424",
  storageBucket: "ingubokagogo-13424.appspot.com",
  messagingSenderId: "117414453956",
  appId: "1:117414453956:web:4e8a86b09c9a52b3919f91",
  measurementId: "G-0GBGD0V42T",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
