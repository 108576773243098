import React from "react";
import "../css/detailsThumb.css";

function DetailsThumb({ Images, setIndex }) {
  return (
    <div className="productDetails__thumb">
      {Images.map((img, index) => (
        <img src={img} alt="" key={index} onClick={() => setIndex(index)} />
      ))}
    </div>
  );
}

export default DetailsThumb;
